import Drawer, {DrawerContent, DrawerHeader, DrawerSubtitle, DrawerTitle} from "@material/react-drawer";
import List, {ListItem, ListItemGraphic, ListItemText} from "@material/react-list";
import MaterialIcon from "@material/react-material-icon";
import React from "react";
import PropTypes from "prop-types";

function AppDrawer(props) {
    return <Drawer
        modal
        open={props.open}
        onClose={props.onClose}
    >

        {/* Header */}
        <DrawerHeader>
            <DrawerTitle>
                {props.memberID}
            </DrawerTitle>
            <DrawerSubtitle>
                Stadtbücherei Lübeck
            </DrawerSubtitle>
        </DrawerHeader>

        {/* Menu */}
        <DrawerContent>
            {props.loggedIn ?
                <List>
                    {/* Einstellungen */}
                    {/*<ListItem onClick={() => {*/}
                    {/*    props.onClose();*/}
                    {/*}}>*/}
                    {/*    <ListItemGraphic graphic={<MaterialIcon icon='settings'/>}/>*/}
                    {/*    <ListItemText primaryText='Einstellungen'/>*/}
                    {/*</ListItem>*/}

                    {/* Logout */}
                    <ListItem onClick={() => {
                        props.onLogout();
                        props.onClose();
                    }}>
                        <ListItemGraphic graphic={<MaterialIcon icon='exit_to_app'/>}/>
                        <ListItemText primaryText='Abmelden'/>
                    </ListItem>
                </List> :
                <List>
                    <ListItem onClick={() => {
                        props.onClose();
                    }}>
                        <ListItemGraphic graphic={<MaterialIcon icon='home'/>}/>
                        <ListItemText primaryText='Anmelden'/>
                    </ListItem>
                </List>
            }
        </DrawerContent>
    </Drawer>;
}

Drawer.propTypes = {
    // Open state
    open: PropTypes.bool.isRequired,

    // Action handlers
    onClose: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,

    // Extra meta
    memberID: PropTypes.string,
    loggedIn: PropTypes.bool.isRequired
};


export default AppDrawer;