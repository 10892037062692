function formatMoment(moment) {
    return moment.format("DD.MM.YYYY");
}

function fetchData(url = '', data = {}) {
    // Default options are marked with *
    return fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, cors, *same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        // redirect: 'follow', // manual, *follow, error
        // referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).then(function (response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    })
        .then(response => response.json()); // parses JSON response into native Javascript objects
}

export {formatMoment, fetchData};