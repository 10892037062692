import PropTypes from "prop-types";
import React from "react";
import Loans from "./Loans";
import StatusWithIcon from "./StatusWithIcon";

import LinearProgress from '@material/react-linear-progress';
import NotClosableDialog from "./NotClosableDialog";
import {Overline} from "@material/react-typography";
import moment from "moment";

function LoansScreen(props) {
    return <div>
        {props.online ? <div>
                {props.lastTimeRefreshed ? <div className={"flex center muted"}>
                    <Overline>Aktualisiert {props.lastTimeRefreshed.fromNow()}</Overline>
                </div> : ""}
                <Loans loans={props.loans} loanInDialog={props.loanInDialog} onCloseLoanDialog={props.onCloseLoanDialog}
                       onOpenLoanDialog={props.onOpenLoanDialog}/>
            </div> :
            <StatusWithIcon text={"Du hast keine Internetverbindung!"} icon={"wifi_off"}/>}
        <NotClosableDialog title={"Wird aktualisiert ..."} open={props.isRefreshing}>
            <LinearProgress indeterminate/>
        </NotClosableDialog>
    </div>;
}

LoansScreen.propTypes = {
    loans: PropTypes.array.isRequired,
    online: PropTypes.bool.isRequired,
    isRefreshing: PropTypes.bool.isRequired,
    lastTimeRefreshed: PropTypes.objectOf(moment).isRequired,
    loanInDialog: PropTypes.number.isRequired,
    onOpenLoanDialog: PropTypes.func.isRequired,
    onCloseLoanDialog: PropTypes.func.isRequired,
};
export default LoansScreen;