import React, {Component} from "react";
import TextField, {HelperText, Input} from '@material/react-text-field';

import {Button} from "@material/react-button";
import {fetchData} from "../help";
import {Snackbar} from '@material/react-snackbar';
import PropTypes from "prop-types";
import LinearProgress from "@material/react-linear-progress";
import NotClosableDialog from "./NotClosableDialog";
import {API_URL} from "../common";

class Login extends Component {
    constructor(props) {
        super(props);
        const memberID = localStorage.getItem("memberID") || "";

        this.state = {
            memberID: memberID,
            pin: "",
            connectionIssue: false,
            authIssue: false,
            isChecking: false,
        };

        this.snackbar = React.createRef();
    }


    login = () => {
        this.setState({
            connectionIssue: false,
            authIssue: false,
            isChecking: true,
        });
        console.log(API_URL);
        fetchData(API_URL + "check", {memberID: this.state.memberID, pin: this.state.pin})
            .then((data) => {
                console.log(data);
                if (data.code === "bad") {
                    this.setState({
                        authIssue: true,
                        isChecking: false
                    });
                } else if (data.code === "ok") {
                    this.props.onLoggedIn(this.state.memberID, this.state.pin);
                    localStorage.setItem("memberID", this.state.memberID);
                }

            })
            .catch(() => {
                this.setState({
                    connectionIssue: true,
                    isChecking: false
                });
            });
    };


    render() {

        return <div className={"padding"}>
            <form onSubmit={(e) => {
                e.preventDefault();
                this.login();
            }}>
                {/* Member ID*/}
                <div className={"margin-after"}>
                    <TextField
                        outlined
                        className={"fullsize"}
                        label='Ausweisnummer'
                        helperText={<HelperText>Bitte die Ausweisnummer inkl. Buchstaben eingeben</HelperText>}
                    >
                        <Input
                            value={this.state.memberID}
                            onChange={(e) => this.setState({memberID: e.target.value})}
                        />
                    </TextField>

                </div>

                {/* PIN */}
                <div className={"margin-after"}>
                    <TextField
                        outlined
                        className={"fullsize"}
                        label='PIN'
                        helperText={<HelperText>vierstellig, Geburtstag und Geburtsjahr</HelperText>}
                    >
                        <Input
                            value={this.state.pin}
                            onChange={(e) => this.setState({pin: e.target.value})}
                            type={"password"}/>
                    </TextField>
                </div>

                {/* Login button */}
                <div className={"margin-after"}>
                    <Button
                        raised
                        onClick={this.login}
                        type={"submit"}
                    >
                        Anmelden
                    </Button>
                </div>
            </form>

            {/* Issue toasts */}
            {this.state.connectionIssue ? <Snackbar
                message="Die Verbindung zum Server ist fehlgeschlagen. Bitte prüfe, ob du eine aktive Internetverbindung hast!"
                actionText="OK"/> : ""}
            {this.state.authIssue ? <Snackbar
                message="Die Anmeldung ist fehlgeschlagen. Bitte prüfe die eingegebene Ausweisnummer und die PIN!"
                actionText="OK"/> : ""}

            {/* On progress dialog*/}
            <NotClosableDialog title={"Wird überprüft ..."} open={this.state.isChecking}>
                <LinearProgress indeterminate/>
            </NotClosableDialog>
        </div>;
    }
}

Login.propTypes = {
    onLoggedIn: PropTypes.func.isRequired
}
;
export default Login;