import PropTypes from "prop-types";
import React from "react";
import Dialog, {DialogContent, DialogTitle} from "@material/react-dialog";

function NotClosableDialog(props) {
    return <Dialog open={props.open} escapeKeyAction={""} scrimClickAction={""}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            {props.children}
        </DialogContent>
    </Dialog>;
}

NotClosableDialog.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

export default NotClosableDialog;