import TopAppBar, {TopAppBarIcon, TopAppBarRow, TopAppBarSection, TopAppBarTitle} from "@material/react-top-app-bar";
import MaterialIcon from "@material/react-material-icon";
import React from "react";
import PropTypes from "prop-types";

function AppTopBar(props) {
    return <TopAppBar dense>
        <TopAppBarRow>
            <TopAppBarSection align='start'>
                <TopAppBarIcon navIcon tabIndex={0}>
                    <MaterialIcon hasRipple icon='menu'
                                  aria-label={"Menü"}
                                  onClick={props.onDrawerOpen}/>
                </TopAppBarIcon>
                <TopAppBarTitle>BibApp</TopAppBarTitle>
            </TopAppBarSection>
            {props.loggedIn ? <TopAppBarSection align='end' role='toolbar'>
                <TopAppBarIcon actionItem tabIndex={0}>
                    <MaterialIcon
                        aria-label="Ausleihen neuladen"
                        hasRipple
                        icon='refresh'
                        onClick={props.onRefresh}
                    />
                </TopAppBarIcon>
            </TopAppBarSection> : ""}
        </TopAppBarRow>
    </TopAppBar>;
}

AppTopBar.propTypes = {
    onDrawerOpen: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired
};

export default AppTopBar;