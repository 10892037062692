import PropTypes from "prop-types";
import React from "react";
import List, {ListItem, ListItemText, ListItemGraphic} from "@material/react-list";
import MaterialIcon from "@material/react-material-icon";
import {formatMoment} from "../help";
import StatusWithIcon from "./StatusWithIcon";
import Dialog, {DialogContent, DialogTitle} from "@material/react-dialog";

function Loans(props) {
    const loans = props.loans;
    return <div>
        {props.loanInDialog ? <Dialog open={true} onClose={props.onCloseLoanDialog}>
            <DialogTitle>{props.loanInDialog.title}</DialogTitle>
            <DialogContent>
                <p>
                    <MaterialIcon icon={"person"} className={"left"}/>
                    {props.loanInDialog.author}
                </p>
                <p>
                    <MaterialIcon icon={"label"} className={"left"}/>
                    {props.loanInDialog.type.name}
                </p>
            </DialogContent>
        </Dialog> : ""}
        {loans.length > 0 ? <List twoLine>
                {loans.map(function (loan) {
                    return <ListItem key={loan.barcode} onClick={() => {
                        props.onOpenLoanDialog(loan);
                    }}>
                        <ListItemGraphic graphic={<MaterialIcon icon='book'/>}/>
                        <ListItemText
                            primaryText={loan.title}
                            secondaryText={`Ausgeliehen am ${formatMoment(loan.borrowDate)} · Rückgabe am ${formatMoment(loan.backDate)}`}/>
                    </ListItem>;
                })}
            </List> :
            <StatusWithIcon text={"Du hast aktuell keine Bücher ausgeliehen!"} icon={"not_interested"}/>}
    </div>;
}

Loans.propTypes = {
    loans: PropTypes.array.isRequired,
    loanInDialog: PropTypes.number.isRequired,
    onOpenLoanDialog: PropTypes.func.isRequired,
    onCloseLoanDialog: PropTypes.func.isRequired,
};
export default Loans;