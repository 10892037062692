import PropTypes from "prop-types";
import MaterialIcon from "@material/react-material-icon";
import {Overline} from "@material/react-typography";
import React from "react";

function StatusWithIcon(props) {
    return <div>
        <div className={"flex center"}>
            <MaterialIcon icon={props.icon} className={"large"}/>
        </div>
        <div className={"flex center"}>
            <p><Overline>{props.text}</Overline></p>
        </div>
    </div>;
}

StatusWithIcon.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
};

export default StatusWithIcon;