import React, {Component} from 'react';
import './App.css';
import {Route, BrowserRouter as Router} from "react-router-dom";

import '@material/react-material-icon/index.scss';
import "./theme.scss";


import {
    TopAppBarFixedAdjust,

} from '@material/react-top-app-bar';

import {
    DrawerAppContent
} from '@material/react-drawer';
import AppDrawer from "./components/AppDrawer";
import AppTopBar from "./components/AppTopBar";
import Login from "./components/Login";
import * as moment from "moment";
import {fetchData} from "./help";
import LoansScreen from "./components/LoansScreen";
import "moment/locale/de";
import {Snackbar} from "@material/react-snackbar";
import {API_URL} from "./common";

moment.locale("de");


function Users() {
    return <h2>Users</h2>;
}


class App extends Component {
    constructor(props) {
        super(props);

        const memberID = sessionStorage.getItem("memberID");
        const pin = sessionStorage.getItem("pin");


        this.state = {
            drawerOpen: false,
            loans: [],
            online: true,
            loggedIn: memberID && pin,
            // memberID: "A029146714",
            // pin: "0803",
            memberID: memberID || "",
            pin: pin || "",
            isRefreshing: true,
            lastTimeRefreshed: null,
            loanInDialog: null,

        };
    }

    logout = () => {
        this.setState({
            loggedIn: false,
            loggedOut: true,
            memberID: "",
            pin: ""
        });
        sessionStorage.clear();
    };

    closeDrawer = () => {
        this.setState({drawerOpen: false});
    };

    openLoanDialog = (loan) => {
        this.setState({loanInDialog: loan});
    };

    closeLoanDialog = () => {
        this.setState({loanInDialog: null});
    };

    refresh = (automatically = false) => {
        if (!this.state.loggedIn) {
            return;
        }
        if (!automatically) {
            this.setState({isRefreshing: true});
        }
        const that = this;
        fetchData(API_URL + "loans", {memberID: this.state.memberID, pin: this.state.pin})
            .then((data) => {
                console.log(data);
                const loggedIn = data.code === "ok";
                this.setState({
                    online: true,
                    loggedIn: loggedIn,
                    loans: data.data.loans.map((loan) => {
                        loan.borrowDate = moment.unix(loan.borrowDate);
                        loan.backDate = moment.unix(loan.backDate);

                        return loan;
                    }),
                    isRefreshing: false,
                    lastTimeRefreshed: moment()
                });

                if (automatically) {
                    setTimeout(that.refreshAutomatically, 10000);
                }
            })
            .catch(() => {
                this.setState({online: false, isRefreshing: false});

                if (automatically) {
                    setTimeout(that.refreshAutomatically, 1000);
                }
            })
    };

    onLoggedIn = (memberID, pin) => {
        sessionStorage.setItem("memberID", memberID);
        sessionStorage.setItem("pin", pin);

        this.setState({
            online: true,
            loggedIn: true,
            memberID: memberID,
            pin: pin
        });
        this.refreshAutomatically();
    };

    refreshAutomatically = () => {
        this.refresh(true);
    };

    componentDidMount() {
        this.refreshAutomatically();
    }

    render() {
        return <Router>
            <div>
                <AppDrawer open={this.state.drawerOpen} onClose={this.closeDrawer} onLogout={this.logout}
                           memberID={this.state.memberID}
                           loggedIn={this.state.loggedIn}/>
                <DrawerAppContent>
                    <AppTopBar onDrawerOpen={() => this.setState({drawerOpen: !this.state.drawerOpen})}
                               onRefresh={this.refresh}
                               loggedIn={this.state.loggedIn}/>
                    <TopAppBarFixedAdjust>
                        {this.state.loggedIn ? <div>
                            <Route path="/" exact
                                   render={(props) => <LoansScreen {...props} {...this.state}
                                                                   onOpenLoanDialog={this.openLoanDialog}
                                                                   onCloseLoanDialog={this.closeLoanDialog}/>}/>
                            {/*<Route path="/login/"*/}
                            {/*       render={(props) => <Login {...props} state={this.state} setState={this.setState}/>}/>*/}
                            <Route path="/users/" component={Users}/>
                        </div> : <Login onLoggedIn={this.onLoggedIn}/>}

                    </TopAppBarFixedAdjust>
                </DrawerAppContent>
                {this.state.loggedOut === true ?
                    <Snackbar message={"Abmelden erfolgreich"} action={"Wieder anmelden"}/> : ""}
            </div>
        </Router>;
    }
}

export default App;
